import axios, { AxiosError } from 'axios';

import buildUrl from '../buildUrl';
import { UPLOAD_DOCUMENTS_PATH } from '../constants';
import iAxiosGetAllDocumentsResponse from './responseTypes/iAxiosGetAllDocumentsResponse';

export default async function getAllDocuments(sessionId: string) {
  const url = buildUrl(UPLOAD_DOCUMENTS_PATH, sessionId);

  const { data: responseData }: iAxiosGetAllDocumentsResponse = await axios
    .get(url)
    .catch((e: AxiosError) => {
      if (e.response?.data) return e.response as iAxiosGetAllDocumentsResponse;

      throw e;
    });

  return responseData;
}
