import classNames from 'classnames';
import { Button } from 'devextreme-react/button';
import React from 'react';

import spinnerIcon from '../../../icons/icon-spinner.svg';
import FileError from './fileError';
import FileSuccess from './fileSuccess';
import IUploadFile from './iUploadFile';

type IOnRemoveFile = {
  (fileName: string): void;
};

interface IProps extends IUploadFile {
  removeFile: IOnRemoveFile;
}

export default function File({ file, uploading, uploaded, errorMessage, removeFile }: IProps) {
  return (
    <li className="row">
      <div className="col-12 col-md-5">
        {file.name}
        <img
          className={classNames('ms-2', { 'd-none': !uploading })}
          src={spinnerIcon}
          alt="loading icon"
          aria-hidden="true"
        />
      </div>
      <div className="col-12 col-md-7 d-flex align-items-center">
        {!uploaded && (
          <Button
            type="danger"
            icon="trash"
            onClick={() => removeFile(file.name)}
          />
        )}
        {errorMessage && <FileError message={errorMessage} />}
        {uploaded && <FileSuccess />}
      </div>
    </li>
  );
}
