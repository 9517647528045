import classNames from 'classnames';
import React from 'react';

type IStepProps = {
  index: number;
  title: string;
  active: boolean;
  current: boolean;
};

export default function Step({ index, title, active, current }: IStepProps) {
  return (
    <li className={classNames({ 'ib-step-navigation__active': active, current })}>
      <a href={`#${index}`}>
        <span className="ib-step-navigation__circle">{index}</span>
        {title}
      </a>
    </li>
  );
}
