import React from 'react';

import successIcon from '../../../icons/icon-check-circle.svg';

export default function FileSuccess() {
  return (
    <div className="d-flex align-items-center ms-2">
      <img
        src={successIcon}
        alt="Success"
        width={30}
      />
      <p className="ib-text-success ms-1 my-0">Upload complete</p>
    </div>
  );
}
