import axios, { AxiosError } from 'axios';
import buildUrl from '../buildUrl';
import { Delete_Source_Document } from '../constants';
import IAxiosDeleteSourceDocuments from './responseTypes/iAxiosDeleteSourceDocumentsResponse';

export default async function deleteDocuments(documentKey: number, sessionId: string) {
  const url = buildUrl(Delete_Source_Document, sessionId, documentKey.toString());

  const { data: responseData }: IAxiosDeleteSourceDocuments = await axios
    .delete(url)
    .catch((e: AxiosError) => {
      if (e.response?.data) return e.response as IAxiosDeleteSourceDocuments;

      throw e;
    });

  return responseData;
}
