import { Popup } from 'devextreme-react';
import React from 'react';

import loader from '../../../icons/loader.svg';

type IProps = {
  visible: boolean;
};

export default function ImportPopup({ visible }: IProps) {
  return (
    <Popup
      width={420}
      height={280}
      visible={visible}
      showTitle={false}
      showCloseButton={false}
      hideOnOutsideClick={false}
    >
      <div style={{ textAlign: 'center' }}>
        <img
          src={loader}
          alt="loader"
          style={{ height: '60px', width: '60px' }}
        />
      </div>
      <div className="dx-popup-content px-0">
        <div
          className="ib-info-block__text"
          style={{ textAlign: 'center' }}
        >
          <div className="ib-info-block__heading text">We are processing your content</div>
          <div className="ib-info-block__description">This can take up to a minute.</div>
          <div className="ib-info-block__description">Please wait.</div>
        </div>
      </div>
    </Popup>
  );
}
