import React from 'react';

import errorIcon from '../../../icons/icon-error-circle.svg';
import './Error.css';

type ErrorProps = {
  heading: string;
  text: string;
};

export default function Error({ text, heading }: ErrorProps) {
  return (
    <div className="ib-error">
      <div className="container">
        <div className="row">
          <div className="col-1">
            <img
              src={errorIcon}
              alt="Error icon"
            />
          </div>
          <div className="col">
            <h2>{heading}</h2>
            <p>{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
