import axios from 'axios';

import buildUrl from '../buildUrl';
import { STYLE_MAPPINGS_PATH } from '../constants';
import IAxiosStyleMappingResponse from './responseTypes/iAxiosStyleMappingResponse';

export default async function getStyleMapping(sessionId: string) {
  const url = buildUrl(STYLE_MAPPINGS_PATH, sessionId);

  const { data: responseData }: IAxiosStyleMappingResponse = await axios.get(url, {});

  return responseData;
}
