import axios from 'axios';

import buildUrl from '../buildUrl';
import { NAVIGATION_LEVELS_PATH } from '../constants';
import { IAxiosNavigationStyleResponse } from './responseTypes/iAxiosNavigationStyleResponse';

export default async function postNavigationStyles(styles: string[], sessionId: string) {
  const url = buildUrl(NAVIGATION_LEVELS_PATH, sessionId);

  const data = styles.map((key, level) => ({
    Name: key,
    level: level+1,
  }));
  console.log("data : "+JSON.stringify(data));
  const { data: responseData }: IAxiosNavigationStyleResponse = await axios.post(url, data);

  return responseData;
}
