import axios from 'axios';

import buildUrl from '../buildUrl';
import { CONVERSION_RESULT } from '../constants';
import IAxiosConversionResultResponse from './responseTypes/IAxiosConversionResultResponse';

export default async function postStartConversionProcess(sessionId: string) {
  const url = buildUrl(CONVERSION_RESULT, sessionId);

  const { data: responseData }: IAxiosConversionResultResponse = await axios.get(url);

  return responseData;
}
