import { Button } from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import { RequiredRule, Validator } from 'devextreme-react/validator';
import React from 'react';

//import { IStyleMapping } from '../../../api/styleMapping';

type IOnSelectionChanged = {
  (sourceStyleName: string): void;
};

type IOnRemove = {
  (): void;
};

type IProps = {
  index: number;
  style: string;
  styleOptions: string[];
  onSelectionChanged: IOnSelectionChanged;
  onRemove: IOnRemove;
};

export default function Level({ index, styleOptions, onSelectionChanged, onRemove }: IProps) {
  return (
    <div className="dx-field">
      <div className="dx-field-label">Level {index === 0 ? '1 (top level page)' : index + 1}</div>
      <div className="dx-field-value">
        <SelectBox
          placeholder="Style"
          dataSource={styleOptions}
          searchEnabled
          showClearButton
          validationMessageMode={'auto'}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
          onSelectionChanged={(e) => onSelectionChanged(e.selectedItem)}
        >
          <Validator>
            <RequiredRule message="Selection is required" />
          </Validator>
        </SelectBox>
        {index > 0 && (
          <Button
            type="danger"
            className="ms-2"
            icon="trash"
            onClick={() => onRemove()}
          />
        )}
      </div>
    </div>
  );
}
