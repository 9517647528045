import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ISession } from '../../api/guideImportSession';
import { IStyleMappings, getStyleMapping, putMapStyle } from '../../api/styleMapping';
import { ISessionState } from './session';

export interface IStyleMappingsState {
  loading: boolean;
  error: boolean;
  data?: IStyleMappings;
}

const initialState: IStyleMappingsState = {
  loading: false,
  error: false,
};

export const loadStyleMappings = createAsyncThunk<
  IStyleMappings | undefined,
  void,
  { state: { session: ISessionState } }
>('styleMappings/loadMappings', async (_, thunkAPI) => {
  const session = thunkAPI.getState().session.data as ISession;
  if (!session) {
    thunkAPI.rejectWithValue('No session');
  }

  const response = await getStyleMapping(session.sessionId);
  if (response.isSuccess) {
    return response.data;
  }

  thunkAPI.rejectWithValue(response.message);
});

export const saveStyleMappings = createAsyncThunk<
  IStyleMappings | undefined,
  { key: number; styleName: string; mapping: object },
  { state: { session: ISessionState } }
>('styleMappings/saveMappings', async (styleMapping, thunkAPI) => {
  const session = thunkAPI.getState().session.data as ISession;
  if (!session) {
    thunkAPI.rejectWithValue('No session');
  }

  const response = await putMapStyle(styleMapping.key, styleMapping.styleName, styleMapping.mapping, session.sessionId);

  if (response.isSuccess) {
    return response.data;
  }

  thunkAPI.rejectWithValue(response.message);
});

export const styleMappingsSlice = createSlice({
  name: 'styleMappings',
  initialState,
  reducers: {},
  extraReducers: {
    [loadStyleMappings.pending.type]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [loadStyleMappings.fulfilled.type]: (state, action: PayloadAction<IStyleMappings>) => {
      state.loading = false;

      state.data = action.payload;
    },
    [loadStyleMappings.rejected.type]: (state) => {
      state.error = true;
    },
    [saveStyleMappings.pending.type]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [saveStyleMappings.fulfilled.type]: (state, action: PayloadAction<IStyleMappings[]>) => {
      state.loading = false;

      state.data = action.payload[0];
    },
    [saveStyleMappings.rejected.type]: (state) => {
      state.error = true;
    },
  },
});

export const selectStyleMappings = (state: { styleMappings: IStyleMappingsState }) => {
  return state.styleMappings.data;
};

export default styleMappingsSlice.reducer;
