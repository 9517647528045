import axios from 'axios';

import buildUrl from '../buildUrl';
import { GET_SESSION_BY_ID } from '../constants';
import IAxiosSessionResponse from './responseTypes/iAxiosSessionResponse';

export default async function getSessionById(sessionId: string) {
  const url = buildUrl(GET_SESSION_BY_ID, sessionId);

  const { data: responseData }: IAxiosSessionResponse = await axios.get(url, {});

  return responseData;
}
