import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ISession } from '../../api/guideImportSession';
import { ITargetStyle, getTargetStyles } from '../../api/targetStyle';
import { ISessionState } from './session';

export interface ITargetStyleState {
  loading: boolean;
  error: boolean;
  data: ITargetStyle[];
}

const initialState: ITargetStyleState = {
  loading: false,
  error: false,
  data: [],
};

export const loadTargetStyles = createAsyncThunk<
  ITargetStyle[] | undefined,
  void,
  { state: { session: ISessionState } }
>('targetStyles/loadTargetStyles', async (_, thunkAPI) => {
  const session = thunkAPI.getState().session.data as ISession;
  if (!session) {
    thunkAPI.rejectWithValue('No session');
  }

  const response = await getTargetStyles(session.moShortCode);
  if (response.isSuccess) {
    return response.data;
  }

  thunkAPI.rejectWithValue(response.message);
});

export const targetStylesSlice = createSlice({
  name: 'targetStyles',
  initialState,
  reducers: {},
  extraReducers: {
    [loadTargetStyles.pending.type]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [loadTargetStyles.fulfilled.type]: (state, action: PayloadAction<ITargetStyle[]>) => {
      state.loading = false;

      state.data = action.payload;
    },
    [loadTargetStyles.rejected.type]: (state) => {
      state.error = true;
    },
  },
});

export const selectTargetStyles = (state: { targetStyles: ITargetStyleState }) => {
  return state.targetStyles.data;
};

export default targetStylesSlice.reducer;
