import React from 'react';

import errorIcon from '../../../icons/icon-error-circle.svg';

type IProps = {
  message: string;
};

export default function FileError({ message }: IProps) {
  return (
    <div className="d-flex align-items-center ms-2">
      <img
        src={errorIcon}
        alt="Error"
        width={30}
      />
      <p className="ib-text-danger ms-1 my-0">{message}</p>
    </div>
  );
}
