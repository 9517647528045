// public enum WizardStepEnums
// {
//     SessionCreated = 0,
//     DocumentUploaded,
//     StylesMapped,
//     NavigationStylesMapped,
//     Finalized,
//     Splitted,
//     Processing,
//     Processed
// }

export enum WizardSteps {
  'Introduction' = 'SessionCreated',
  'UploadFiles' = 'DocumentUploaded',
  'Styles' = 'StylesMapped',
  'Navigation' = 'NavigationStylesMapped',
  'Preview' = 'Finalized',
  'Processing' = 'Processing',
  'Complete' = 'Processed',
  'CallBackDone' = 'CallBackDone'
}

export default interface ISession {
  sessionId: string;
  guideShortCode: string;
  guideName: string;
  moShortCode: string;
  moName: string;
  modifiedBy: null;
  isActive: boolean;
  lastModified: Date;
  createdTime: Date;
  wizardStep: WizardSteps;
  defaultStyleId: null;
  defaultStyle: null;
  isDeleted: boolean;
}
