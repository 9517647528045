import React from 'react';

import Step from './Step';

type INavProps = {
  stepTitles: string[];
  currentStepIndex: number;
};

export default function Nav({ stepTitles, currentStepIndex }: INavProps) {
  return (
    <nav className="ib-step-navigation">
      <ul>
        {stepTitles.map((stepTitle, i) => {
          const stepIndex = i + 1;

          return (
            <Step
              key={stepIndex}
              index={stepIndex}
              title={stepTitle}
              active={stepIndex <= currentStepIndex}
              current={stepIndex === currentStepIndex}
            />
          );
        })}
      </ul>
    </nav>
  );
}
