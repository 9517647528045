import React from 'react';
import { useAuth } from 'react-oidc-context';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { AdminDetail, AdminMain } from './components/admin';
import { SessionManger } from './components/managers';
import Loading from './components/molecules/Loading/Loading';
import { Login } from './components/organisms';
import ErrorNoAuth from './components/organisms/Error/ErrorNoAuth/ErrorNoAuth';
import { PageTemplate } from './components/templates';
import SubTemplate from './components/templates/PageTemplate/SubTemplate';
export default function App() {
  const auth = useAuth();

  if (auth.isLoading) {
    return (
      <PageTemplate>
        <Loading />
      </PageTemplate>
    );
  }

  if (auth.error) {
    return (
      <PageTemplate>
        <Router>
          <Routes>
            <Route
              path="/"
              element={<ErrorNoAuth />}
            />
          </Routes>
        </Router>
      </PageTemplate>
    );
  }
  if (auth.isAuthenticated) {
    return (
      <PageTemplate>
        <Router>
          <Routes>
            <Route
              path="/admin"
              element={<AdminMain />}
            />
            <Route
              path="/admin/detail/:id"
              element={<AdminDetail />}
            />

            <Route
              path="/:id"
              element={
                <SubTemplate>
                  <SessionManger></SessionManger>
                </SubTemplate>
              }
            />
            <Route
              path="/"
              element={
                <SubTemplate>
                  <SessionManger></SessionManger>
                </SubTemplate>
              }
            />
          </Routes>
        </Router>
      </PageTemplate>
    );
  }
  return (
    <PageTemplate>
      <Router>
        <Routes>
          <Route
            path="/:id"
            element={<Login />}
          />
          <Route
            path="/"
            element={<Login />}
          />
        </Routes>
      </Router>
    </PageTemplate>
  );
}
