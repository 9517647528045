import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ISession, getSessionById } from '../../api/guideImportSession';
import { WizardSteps } from '../../api/guideImportSession/iSession';

export interface ISessionState {
  loading: boolean;
  error: boolean;
  data?: ISession;
  success: boolean | null;
}

const initialState: ISessionState = {
  loading: false,
  error: false,
  success: null
};

export const loadSession = createAsyncThunk('session/loadSessionById', async (id: string, thunkAPI) => {
  const response = await getSessionById(id);
  if (response.isSuccess) {
    return response.data;
  }

  thunkAPI.rejectWithValue(response.message);
});

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    updateWizardStep: (state, action: PayloadAction<WizardSteps>) => {
      if (state.data) {
        state.data.wizardStep = action.payload;
      }
    },
  },
  extraReducers: {
    [loadSession.pending.type]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [loadSession.fulfilled.type]: (state, action: PayloadAction<ISession>) => {
      state.loading = false;
      state.data = action.payload;
      state.success = true;
    },
    [loadSession.rejected.type]: (state) => {
      state.error = true;
      state.success = false;
    },
  },
});

export const selectSession = (state: { session: ISessionState }) => {
  return state.session.data;
};

export const selectSessionSuccess = (state: { session: ISessionState }) => {
  return state.session.success;
};

export const { updateWizardStep } = sessionSlice.actions;
export default sessionSlice.reducer;
