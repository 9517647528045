import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { loadTargetStyles, selectTargetStyles } from '../app/reducers/targetStyles';

export default function useTargetStyles() {
  const dispatch = useAppDispatch();

  const targetStyles = useAppSelector(selectTargetStyles);

  useEffect(() => {
    if (!targetStyles.length) {
      void dispatch(loadTargetStyles());
    }
  }, [dispatch, targetStyles.length]);

  return targetStyles || [];
}
