import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { iDocumentStyles } from '../../../api/admin'

export default function StylesList(props: { styles: iDocumentStyles[] }) {
    const columns: TableColumn<iDocumentStyles>[] = [
        {
            name: 'Ms Doc. Style',
            selector: row => row.sourceStyleName,
            allowOverflow: false,
            grow: 2,
            style: {
                backgroundColor: 'rgba(187, 204, 221, 1)',
            },
        },
        {
            name: 'TargetStyle',
            selector: row => row.targetStyle?.name,
            sortable: true,
            allowOverflow: false,
            grow: 2,
            wrap: true
        }

    ];
    return (
        <>
            <DataTable
                title='Style Mappings'
                columns={columns}
                data={props.styles}
                pagination
                persistTableHead
            />
        </>
    )

}