import React from 'react';

type InfoBlockProps = {
  icon: string;
  iconAlt: string;
  heading: string;
  description: string;
};

export default function InfoBlock({ icon, iconAlt, heading, description }: InfoBlockProps) {
  return (
    <div className="ib-info-block">
      <div className="ib-info-block__icon">
        <img
          src={icon}
          alt={iconAlt}
          aria-hidden="true"
        />
      </div>
      <div className="ib-info-block__text">
        <p className="ib-info-block__heading my-0">{heading}</p>
        <div className="ib-info-block__description">{description}</div>
      </div>
    </div>
  );
}
