import axios, { AxiosError } from 'axios';

import buildUrl from '../buildUrl';
import { PUT_UPDATE_WIZARD_STEP } from '../constants';
import { WizardSteps } from './iSession';
import IAxiosSessionResponse from './responseTypes/iAxiosSessionResponse';

export default async function putUpdateWizardStep(nextStep: WizardSteps, sessionId: string, orgShortCode: string) {
  const url = buildUrl(PUT_UPDATE_WIZARD_STEP, orgShortCode, sessionId);

  const { data: responseData }: IAxiosSessionResponse = await axios
    .put(url, nextStep, {
      headers: { 'Content-Type': 'application/json' },
    })
    .catch((e: AxiosError) => {
      if (e.response?.data) return e.response as IAxiosSessionResponse;

      throw e;
    });

  return responseData;
}
