import { useEffect } from 'react';

import { IStyleMappings } from '../api/styleMapping';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { loadStyleMappings, selectStyleMappings } from '../app/reducers/styleMappings';

const defaultValue: IStyleMappings = {
  key: 0,
  styleName: '',
  styleMappings: [],
  sessionId: '',
  guideImportSession: null,
};

export default function useStyleMappings() {
  const dispatch = useAppDispatch();

  const styleMappings = useAppSelector(selectStyleMappings);

  useEffect(() => {
    if (!styleMappings) {
      void dispatch(loadStyleMappings());
    }
  }, [dispatch, styleMappings]);

  return styleMappings || defaultValue;
}
