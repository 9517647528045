import axios, { AxiosError } from 'axios';

import buildUrl from '../buildUrl';
import { UPLOAD_DOCUMENTS_PATH } from '../constants';
import IAxiosSourceDocumentsResponse from './responseTypes/iAxiosSourceDocumentsResponse';

export default async function postCreateDocuments(file: File, sessionId: string) {
  const url = buildUrl(UPLOAD_DOCUMENTS_PATH, sessionId);

  const formData = new FormData();
  formData.append('contentFile', file);

  const { data: responseData }: IAxiosSourceDocumentsResponse = await axios
    .post(url, formData)
    .catch((e: AxiosError) => {
      if (e.response?.data) return e.response as IAxiosSourceDocumentsResponse;

      throw e;
    });

  return responseData;
}
