import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { loadSession, selectSession } from '../app/reducers/session';

export default function useSession(sessionId: string | undefined) {
  const dispatch = useAppDispatch();

  const session = useAppSelector(selectSession);

  useEffect(() => {
    if (sessionId && !session) {
      void dispatch(loadSession(sessionId));
    }
  }, [dispatch, session, sessionId]);

  return session;
}
