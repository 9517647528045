import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ISession } from '../../api/guideImportSession';
import { INavigationStyle, getNavigationStyles, postNavigationStyles } from '../../api/navigationStyle';
import { getNavigationStyleOptions } from '../../api/navigationStyle/getNavigationStyles';
import { ISessionState } from './session';

export interface INavigationStyleState {
  loading: boolean;
  error: boolean;
  success: boolean;
  data: INavigationStyle[];
  options: string[];
}

const initialState: INavigationStyleState = {
  loading: false,
  error: false,
  success: false,
  data: [],
  options: []
};

export const loadNavigationStyleOptions = createAsyncThunk<
  string[] | undefined,
  void,
  { state: { session: ISessionState } }
>('navigationStyles/loadNavigationStyleOptions', async (_, thunkAPI) => {
  const session = thunkAPI.getState().session.data as ISession;
  if (!session) {
    thunkAPI.rejectWithValue('No session');
  }

  const response = await getNavigationStyleOptions(session.sessionId);
  if (response.isSuccess) {
    return response.data;
  }

  thunkAPI.rejectWithValue(response.message);
});

export const loadNavigationStyles = createAsyncThunk<
  INavigationStyle[] | undefined,
  void,
  { state: { session: ISessionState } }
>('navigationStyles/loadNavigationStyles', async (_, thunkAPI) => {
  const session = thunkAPI.getState().session.data as ISession;
  if (!session) {
    thunkAPI.rejectWithValue('No session');
  }

  const response = await getNavigationStyles(session.sessionId);
  if (response.isSuccess) {
    return response.data;
  }

  thunkAPI.rejectWithValue(response.message);
});


export const saveNavigationStyles = createAsyncThunk<
  INavigationStyle[] | undefined,
  string[],
  { state: { session: ISessionState } }
>('navigationStyles/saveNavigationStyles', async (styles, thunkAPI) => {
  const session = thunkAPI.getState().session.data as ISession;
  if (!session) {
    thunkAPI.rejectWithValue('No session');
  }

  const response = await postNavigationStyles(styles, session.sessionId);
  if (response.isSuccess) {
    return response.data;
  }

  thunkAPI.rejectWithValue(response.message);
});

export const navigationStylesSlice = createSlice({
  name: 'navigationStyles',
  initialState,
  reducers: {},
  extraReducers: {
    [loadNavigationStyles.pending.type]: (state) => {
      state.loading = true;
    },
    [loadNavigationStyles.fulfilled.type]: (state, action: PayloadAction<INavigationStyle[]>) => {
      state.success = true;
      state.loading = false;

      state.data = action.payload || [];
    },
    [loadNavigationStyles.rejected.type]: (state) => {
      state.success = false;
      state.error = true;
    },
    [loadNavigationStyleOptions.pending.type]: (state) => {
      state.loading = true;
    },
    [loadNavigationStyleOptions.fulfilled.type]: (state, action: PayloadAction<string[]>) => {
      state.success = true;
      state.loading = false;

      state.options = action.payload || [];
    },
    [loadNavigationStyleOptions.rejected.type]: (state) => {
      state.success = false;
      state.error = true;
    },
    [saveNavigationStyles.pending.type]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [saveNavigationStyles.fulfilled.type]: (state, action: PayloadAction<INavigationStyle[]>) => {
      state.loading = false;
      state.data = action.payload || [];
    },
    [saveNavigationStyles.rejected.type]: (state) => {
      state.error = true;
    },
  },
});

export const selectNavigationStyles = (state: { navigationStyles: INavigationStyleState }) => {
  return state.navigationStyles.data;
};
export const selectNavigationStyleOptions = (state: { navigationStyles: INavigationStyleState }) => {
  return state.navigationStyles.options;
};

export default navigationStylesSlice.reducer;
