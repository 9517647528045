import React from 'react';

import logo from '../../../images/MicroGuideLogo.svg';
import './Header.css';

export default function Header() {
  return (
    <header className="ib-header">
      <div className="container">
        <a href="/">
          <img
            src={logo}
            alt="MicroGuide logo"
          />
        </a>
      </div>
    </header>
  );
}
