import React from 'react';

import { ISession } from '../api/guideImportSession';
import { WizardSteps } from '../api/guideImportSession/iSession';

const defaultValue = {
  wizardStep: WizardSteps.Introduction,
} as ISession;

export const SessionContext = React.createContext<ISession>(defaultValue);
