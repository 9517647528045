import { Popup } from 'devextreme-react';
import React from 'react';

import loader from '../../../icons/loader.svg';

type IProps = {
  visible: boolean;
  showTitle: boolean;
  titleText: string;
  descriptionText: string;
};

export default function IbPopup({ visible, showTitle, titleText, descriptionText }: IProps) {
  return (
    <Popup
      width={420}
      height={280}
      visible={visible}
      showTitle={showTitle}
      showCloseButton={false}
      hideOnOutsideClick={false}
    >
      <div style={{ textAlign: 'center' }}>
        <img
          src={loader}
          alt="loader"
          style={{ height: '60px', width: '60px' }}
        />
      </div>
      <div className="dx-popup-content px-0">
        <div
          className="ib-info-block__text"
          style={{ textAlign: 'center' }}
        >
          <div className="ib-info-block__heading text">{titleText}</div>
          <div className="ib-info-block__description">{descriptionText}</div>
        </div>
      </div>
    </Popup>
  );
}
