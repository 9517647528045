import React from 'react';
import { useAuth } from 'react-oidc-context';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
export default function Login() {
  const auth = useAuth();

    //If we arrive here and have a sessionId and NOT authenticated, store the sessionId locally to use later
  const { id: sessionId } = useParams();
  console.log("SessionId: " ,sessionId);

  const [cookies, setCookie] = useCookies(['preLoginSessionId']);
  console.log(cookies);
  setCookie('preLoginSessionId', sessionId);
  // return <div>{ void auth.signinSilent() }</div>
  return <div>{void auth.signinRedirect()}</div>;
}
