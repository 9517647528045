import React, { useEffect, useRef, useState } from 'react';

import './Collapsible.css';

type Props = {
  open?: boolean;
  header: string | React.ReactNode;
  headerClassName?: string;
  titleClassName?: string;
  iconButtonClassName?: string;
  contentClassName?: string;
  contentContainerClassName?: string;
  collapsibleClassName?: string;
  children: React.ReactNode;
};

const Collapsible = ({
  open,
  collapsibleClassName = 'collapsible-card',
  headerClassName = 'collapsible-header',
  titleClassName = 'title-text',
  iconButtonClassName = 'collapsible-icon-button',
  contentClassName = 'collapsible-content',
  contentContainerClassName = 'collapsible-content-padding',
  children,
  header,
}: Props) => {
  const [isOpen, setIsOpen] = useState(open);
  const [height, setHeight] = useState<number | undefined>(open ? undefined : 0);
  const ref = useRef<HTMLDivElement>(null);
  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!height || !isOpen || !ref.current) return undefined;
    const resizeObserver = new ResizeObserver((el) => {
      setHeight(el[0].contentRect.height);
    });
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [height, isOpen]);

  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
  }, [isOpen]);

  return (
    <>
      <div className={collapsibleClassName}>
        <button
          tabIndex={0}
          id="collapseButton"
          aria-label={`Collapse ${typeof header === 'string' ? header : ''} `}
          className={iconButtonClassName}
          onClick={handleFilterOpening}
        >
          <div className={headerClassName}>
            <div className={titleClassName}>{header}</div>
            <i className={`dx-icon-custom-colllapse ${isOpen ? 'dx-icon-spinup' : 'dx-icon-spindown'}`} />
          </div>
        </button>
        <div
          className={contentClassName}
          style={{ height }}
          aria-expanded={isOpen}
          aria-controls="collapseButton"
        >
          <div ref={ref}>
            <div className={contentContainerClassName}>{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Collapsible;
