import axios from 'axios';

import buildUrl from '../buildUrl';
import { All_SESSIONS_PATH } from '../constants';
import IAxiosAllSessionsResponse from './responseTypes/iAxiosAllSessionsResponse';
export default async function getAllSessions() {
  const url = buildUrl(All_SESSIONS_PATH);

  const { data: responseData }: IAxiosAllSessionsResponse = await axios.get(url, {});

  return responseData;
}
