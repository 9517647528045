import React from 'react';

import Footer from '../../organisms/Footer/Footer';
import Header from '../../organisms/Header/Header';
import './PageTemplate.css';

type IProps = {
  children: React.ReactNode;
};

export default function PageTemplate(props: IProps) {
  return (
    <div className="ib-page-template dx-swatch-induction-scheme">
      <Header />

      <div className="ib-page-template__content">
             {props.children}
      </div>

      <Footer />
    </div>
  );
}
