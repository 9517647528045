import axios from 'axios';

import buildUrl from '../buildUrl';
import { SESSION_PATH } from '../constants';
import IAxiosDeleteSessionResponse from './responseTypes/iAxiosDeleteSessionResponse';

export default async function delSession(sessionId : string) {
  const url = buildUrl(SESSION_PATH, sessionId);

  const { data: responseData }: IAxiosDeleteSessionResponse = await axios.delete(url, {});

  return responseData;
}
