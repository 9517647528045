import 'bootstrap/dist/css/bootstrap-grid.css';
import 'bootstrap/dist/css/bootstrap-reboot.css';
import 'bootstrap/dist/css/bootstrap-utilities.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider  } from 'react-oidc-context';
import { Provider } from 'react-redux';

import App from './App';
import { store } from './app/store';
import reportWebVitals from './reportWebVitals';
import './styles/dx.generic.induction-scheme.css';
import './styles/index.css';
import { OIDC_CONFIG } from './constants';
import { CookiesProvider } from 'react-cookie';


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
console.log(OIDC_CONFIG);
// const preLoginSessionId = 'a665fe32-943e-4267-a836-daafc211c2a2';

// const oidc = {
//   authority: OIDC_CONFIG.authority,
//   client_id: OIDC_CONFIG.client_id,
//   redirect_uri: OIDC_CONFIG.redirect_uri + preLoginSessionId,
//   scope: OIDC_CONFIG.scope,
//   response_type: OIDC_CONFIG.response_type,
//   client_secret: OIDC_CONFIG.client_secret,
// };

root.render(
  <AuthProvider {...OIDC_CONFIG}>
    <React.StrictMode>
      <Provider store={store}>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </Provider>
    </React.StrictMode>
  </AuthProvider>
);
reportWebVitals();
