// DX components
import { TreeView } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import React, { useContext, useEffect, useState } from 'react';

import {
  getConversionResult,
  postStartConversionProcess,
  putConfirmContentIntoCms,
} from '../../../api/conversion/index';
import IConversionResultResponse, {
  IDocumentErrorDetail,
  INavigation,
} from '../../../api/conversion/responseTypes/IConversionResultResponse';
import { WizardSteps } from '../../../api/guideImportSession/iSession';
import { deleteDocuments } from '../../../api/sourceDocument';
import poll from '../../../app/apiPolling';
import { SessionContext } from '../../../contexts/sessionContext';
import { useAdvanceWizardStep } from '../../../hooks/useAdvanceWizardStep';
import { useNavigationStyles } from '../../../hooks/useNavigationStyles';
import useStyleMappings from '../../../hooks/useStyleMappings';
import errorIcon from '../../../icons/icon-error-circle.svg';
import infoIcon from '../../../icons/icon-info-circle.svg';
// Icons
import searchIcon from '../../../icons/icon-search-circle.svg';
import { Collapsible } from '../../molecules';
import ImportPopup from './importingPopup';

export default function Step4() {
  const advanceWizardStep = useAdvanceWizardStep();
  const navigationStyles = useNavigationStyles();
  const styleMappings = useStyleMappings();
  const session = useContext(SessionContext);
  const [isImportComplete, setImportComplete] = useState(false);
  const [conversionResult, setConversionResult] = useState<INavigation[]>([]);
  const [conversionErrors, setConversionErrors] = useState<IDocumentErrorDetail[]>([]);
  const [deleteDocumentKey, setDeleteDocumentKey] = useState(0);

  const [importing, setImporting] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);

  const getResult = () => getConversionResult(session.sessionId);
  const validate = (result: IConversionResultResponse) => {
    if (result.isSuccess == true) {
      return true;
    } else {
      return false;
    }
  };

  function handlePreviousClick() {
    // eslint-disable-next-line
    advanceWizardStep(WizardSteps.Navigation);
  }
  const onCancelDeleteClicked = () => {
    setPopupVisible(false);
  };
  const onDeleteClicked = (documentKey: number) => {
    setDeleteDocumentKey(documentKey);
    setPopupVisible(true);
  };

  function deleteFile() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      console.log('DELETE FILE: ' + deleteDocumentKey.toString());
      const response = await deleteDocuments(deleteDocumentKey, session.sessionId);
      if (response.isSuccess) {
        await handleImportClick();
      }
    })();
  }

  async function processConversionResult(response: IConversionResultResponse) {
    if (response.isSuccess) {
      //update UI to show navigation tree on this page
      //add expanded value to each item
      const newArr = response.data.navigation.map((element) => ({
        ...element,
        guideNavigation: { ...element.guideNavigation, expanded: true },
      }));
      setConversionResult(newArr);
      setConversionErrors(response.data.documentErrorDetails);
      setImporting(false);
      //enable the continue button to finalise
      setImportComplete(true);
      await advanceWizardStep(WizardSteps.Preview);
    } else {
      //error handling
    }
  }

  async function handleImportClick() {
    setImporting(true);
    console.log(session.wizardStep);
    console.log(WizardSteps.Complete);
    if (session.wizardStep == WizardSteps.Complete) {
      const response = await getConversionResult(session.sessionId);
      await processConversionResult(response);
    } else {
      const startResult = await postStartConversionProcess(session.sessionId);
      if (startResult.isSuccess) {
        const response = (await poll(getResult, validate, 1000)) as IConversionResultResponse;
        await processConversionResult(response);
      }
    }
  }
  async function handleContinueClick() {
    setImporting(true);
    await advanceWizardStep(WizardSteps.Complete);
    const result = await putConfirmContentIntoCms(session.sessionId);
    if (result.isSuccess) {
      //wizard step out of sync -> switched to new state "CallBackDone" for final state
      setImporting(false);
      await advanceWizardStep(WizardSteps.CallBackDone);
    }
  }
  //catch then catch in place to get rid of linting error for promises
  useEffect(() => {
    (async function () {
      await handleImportClick();
    })()
      .catch(() => console.log('error'))
      .then(() => console.log('this will succeed'))
      .catch(() => 'obligatory catch');
  }, []);

  const cancelButtonOptions = {
    icon: 'clear',
    text: 'Cancel',
    onClick: onCancelDeleteClicked,
  };
  const deleteButtonOptions = {
    icon: 'trash',
    text: 'Delete',
    onClick: deleteFile,
  };

  return (
    <>
      <h1>Import MS Word Files</h1>
      <div className="ib-info-block align-items-center">
        <div className="ib-info-block__icon">
          <img
            src={searchIcon}
            alt="upload icon"
            aria-hidden="true"
          />
        </div>
        <div className="ib-info-block__text">
          <h2 className="ib-info-block__heading my-0">Step 4. Check and import</h2>
        </div>
      </div>
      <p>
        Check the details below. When you&apos;re ready, select <strong>Import</strong> to populate the CMS.
      </p>
      <h3>Settings</h3>
      <table className="ib-table mb-5">
        <tbody>
          <tr>
            <th>Guide</th>
            <td>{session.guideShortCode}</td>
            <td></td>
          </tr>
          <tr>
            <th>Settings name</th>
            <td>{session.guideName}</td>
            <td></td>
          </tr>

          <tr>
            <th>Mapping</th>
            <th>Word style</th>
            <th>MicroGuide style</th>
          </tr>
          {styleMappings.styleMappings.map((item, i) => (
            <tr key={i}>
              <td></td>
              <td>{item.sourceStyleName}</td>
              <td>{item.targetStyle?.name}</td>
            </tr>
          ))}
          <tr>
            <th>Navigation</th>
          </tr>
          {navigationStyles.map((item, i) => (
            <tr key={i}>
              <td></td>
              <td>
                {item.name} <span className="text-secondary">(Level {item.level})</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <h3>Navigation preview</h3>
      {conversionResult?.length > 0 ? (
        <p className="fs-6">
          Navigation nodes, identified by an arrow ({' '}
          <i
            style={{ fontSize: '20px' }}
            className="dx-icon-spindown"
          ></i>{' '}
          ), will not have content. Details for warnings and/or errors in each file appear below the navigation preview.
        </p>
      ) : (
        <p className="fs-6">Navigation nodes will appear below, once the import is complete </p>
      )}

      <TreeView
        className="ib-tree-view"
        items={conversionResult}
        visible={conversionResult?.length > 0}
        dataStructure="plain"
        //itemsExpr="guideNavigation"
        keyExpr="guideNavigation.id"
        displayExpr="guideNavigation.title"
        parentIdExpr="guideNavigation.parentId"
        expandNodesRecursive={true}
        expandedExpr="guideNavigation.expanded"
      />

      <h3>Files ({conversionErrors.length})</h3>
      <ul className="ib-upload-file-list mb-5">
        {conversionErrors.map((document) => (
          <li
            className="d-block"
            key={document.key}
          >
            <div className="d-flex justify-content-between">
              <div className="ib-upload-file-list__file-info">
                <h5 className="mb-0">{document.fullName}</h5>
              </div>
              <div>
                <Button
                  type="danger"
                  width={43}
                  height={43}
                  icon="trash"
                  onClick={() => onDeleteClicked(document.key)}
                />
              </div>
            </div>
            <div className="flex-grow-1">
              <Collapsible
                open
                header={'Errors and warnings (' + document.errorList.length.toString() + ')'}
              >
                {document.errorList.map((error, errorKey) =>
                  error.isWarning ? (
                    <div
                      className="ib-info-block error"
                      key={errorKey + 1}
                    >
                      <div className="ib-info-block__icon text-warning fw-bold">
                        {errorKey + 1}{' '}
                        <img
                          src={infoIcon}
                          alt="upload icon"
                          aria-hidden="true"
                          width={40}
                        />
                      </div>
                      <div className="ib-info-block__text small">
                        <div className="ib-info-block__heading text-dark-warning">{error.errorMessage}</div>
                        <div className="ib-info-block__description">
                          Content before <strong>{error.detail}</strong> (navigation node) will not be imported.
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="ib-info-block error"
                      key={errorKey + 1}
                    >
                      <div className="ib-info-block__icon text-danger fw-bold">
                        {errorKey + 1}{' '}
                        <img
                          src={errorIcon}
                          alt="upload icon"
                          aria-hidden="true"
                          width={40}
                        />
                      </div>
                      <div className="ib-info-block__text small">
                        <div className="ib-info-block__heading text-danger">{error.errorMessage}</div>
                        <div className="ib-info-block__description">
                          Content before <strong>{error.detail}</strong> (navigation node) will not be imported.
                        </div>
                      </div>
                    </div>
                  )
                )}
              </Collapsible>
            </div>
          </li>
        ))}
      </ul>

      <div className="d-flex justify-content-between">
        <div>
          <Button
            type="default"
            stylingMode="outlined"
            className="float-end"
            text="Previous"
            icon="back"
            onClick={handlePreviousClick}
          />
        </div>
        <div>
          <Button
            type="default"
            className="float-end"
            text="Finalise"
            icon="back"
            rtlEnabled
            disabled={!isImportComplete}
            onClick={() => void handleContinueClick()}
          />
        </div>
      </div>
      <ImportPopup visible={importing} />
      <Popup
        visible={popupVisible}
        width={320}
        height={280}
        showTitle={true}
        showCloseButton={false}
        hideOnOutsideClick={false}
        title="Delete Session"
      >
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="before"
          options={deleteButtonOptions}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={cancelButtonOptions}
        />
        <p>Are you sure you want to delete this document?</p>
      </Popup>
    </>
  );
}
