import React from 'react';

// Icons
import checkIcon from '../../icons/icon-check-circle.svg';

export default function Step5() {
  return (
    <>
      <h1>Import MS Word Files</h1>
      <div className="ib-info-block align-items-center">
        <div className="ib-info-block__icon">
          {/* You can replace icon with another one, red circled for exaple */}
          <img
            src={checkIcon}
            alt="Check icon"
            aria-hidden="true"
          />
        </div>
        <div className="ib-info-block__text">
          {/* Use .text-danger class to make heading text danegr red */}
          <h2 className="ib-info-block__heading my-0">Import complete</h2>
        </div>
      </div>
      <p>
        Your import was successful. Close this window to return to MicroGuide. For a full report on the import, go to
        the guide and select <strong>Guide Options &gt; Import Report</strong>.
      </p>
    </>
  );
}
