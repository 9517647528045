// DX components
import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import React, { useState } from 'react';

import { WizardSteps } from '../../../api/guideImportSession/iSession';
import { useAdvanceWizardStep } from '../../../hooks/useAdvanceWizardStep';
// Icons
import documentIcon from '../../../icons/icon-document-circle.svg';
import searchIcon from '../../../icons/icon-search-circle.svg';
import settingsIcon from '../../../icons/icon-settings-circle.svg';
import tickIcon from '../../../icons/icon-tick-circle.svg';
import uploadIcon from '../../../icons/icon-upload-circle.svg';
import InfoBlock from './infoBlock';

export default function Main() {
  const advanceWizardStep = useAdvanceWizardStep();
  const [skipIntroductionPage, setSkipIntroductionPage] = useState(false);

  const handleContinueClick = () => {
    if (skipIntroductionPage) localStorage.setItem('skipIntroductionPage', 'true');
    // eslint-disable-next-line
    advanceWizardStep(WizardSteps.UploadFiles);
  };

  return (
    <div>
      <h1>Import MS Word Files</h1>
      <p>
        This wizard will help you upload Word files with .DOCX or .DOC file extensions, and map the layout styles in
        your files with those in MicroGuide. The summary below explains the process.
      </p>

      {[
        {
          icon: uploadIcon,
          iconAlt: 'Upload icon',
          heading: 'Step 1. Upload files',
          description: 'You can upload multiple Word files and have them appear in the order you choose.',
        },
        {
          icon: settingsIcon,
          iconAlt: 'Settings icon',
          heading: 'Step 2. Map styles',
          description:
            'Map the styles used in your Word file to the styles used in MicroGuide. You can save your mapping settings for future use.',
        },
        {
          icon: documentIcon,
          iconAlt: 'Document icon',
          heading: 'Step 3. Set navigation',
          description: 'Define the navigation using the styles found in the Word files.',
        },
        {
          icon: searchIcon,
          iconAlt: 'Search icon',
          heading: 'Step 4. Preview',
          description: 'Check your settings and begin import.',
        },
        {
          icon: tickIcon,
          iconAlt: 'Tick icon',
          heading: 'Step 5. Done!',
          description: 'Result of importing into MicroGuide.',
        },
      ].map(({ heading, ...rest }) => (
        <InfoBlock
          key={heading}
          heading={heading}
          {...rest}
        />
      ))}

      <div className="d-flex justify-content-between">
        <CheckBox
          text="Skip this step next time"
          value={skipIntroductionPage}
          onValueChanged={(e) => setSkipIntroductionPage(!!e.value)}
        />
        <Button
          type="default"
          text="Continue"
          icon="back"
          rtlEnabled
          onClick={() => handleContinueClick()}
        />
      </div>
    </div>
  );
}
