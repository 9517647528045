import axios from 'axios';

import buildUrl from '../buildUrl';
import { SESSION_ACTIVE } from '../constants';
import IAxiosActiveSessionResponse from './responseTypes/iAxiosActiveSessionResponse';

export default async function putActive(sessionId : string, value : boolean) {
  const url = buildUrl(SESSION_ACTIVE, sessionId, value.toString());

  const { data: responseData }: IAxiosActiveSessionResponse = await axios.put(url, {});

  return responseData;
}
