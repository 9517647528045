import { Button } from 'devextreme-react/button';
import React from 'react';

import IUploadedFile from './iUploadedFile';

type IOnRemoveFile = {
  (fileName: string): void;
};

interface IProps extends IUploadedFile {
  removeFile: IOnRemoveFile;
}

export default function File({ fileName, removeFile }: IProps) {
  return (
    <li className="row">
      <div className="col-12 col-md-5">
        {fileName}
        {/* <img
          className={classNames('ms-2', { 'd-none': !uploading })}
          src={spinnerIcon}
          alt="loading icon"
          aria-hidden="true"
        /> */}
      </div>
      <div className="col-12 col-md-7 d-flex align-items-center">
        <Button
          type="danger"
          icon="trash"
          onClick={() => removeFile(fileName)}
        />
      </div>
    </li>
  );
}
