import { LoadIndicator } from 'devextreme-react';
import React from 'react';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { loadSession, selectSessionSuccess } from '../../app/reducers/session';
import { SessionContext } from '../../contexts/sessionContext';
import useSession from '../../hooks/useSession';
import { ErrorNoSession } from '../organisms';
import PageManager from './PageManager';

export default function SessionManger() {
  const dispatch = useAppDispatch();
  const { id: sessionId } = useParams();
  const session = useSession(sessionId);
  const sessionSuccess = useAppSelector(selectSessionSuccess);

  //If session success is null BUT we have a sessionId held in localStorage (cookie or whatever) then redirect to the root url but with the sessionId added to it.
  const [cookies] = useCookies(['preLoginSessionId']);
  const navigate = useNavigate();
  const [preLoginSessionId, setpreLoginSessionId] = useState<string | null>(null);
  useEffect(() => {
    const stringloginsessionid = cookies.preLoginSessionId as string;
    //reload session based on the sessionId from the cookie
    if (preLoginSessionId && !session) {
      console.log('Pre-login-session check: no session yet');
      //void dispatch(loadSession(preLoginSessionId));
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      (async () => {
        await dispatch(loadSession(preLoginSessionId)).unwrap();
        return navigate({
          pathname: '/' + stringloginsessionid,
        });
      })();
    }
  }, []);

  if (sessionSuccess == null && cookies.preLoginSessionId != null && preLoginSessionId == null) {
    setpreLoginSessionId(cookies.preLoginSessionId as string);
    return (
      <>
        <h2>Loading redirect...</h2>
        <LoadIndicator
          id="large-indicator"
          height={40}
          width={40}
        />
      </>
    );
  } else if (sessionSuccess == null) {
    return (
      <>
        <h2>Loading session data...</h2>
        <LoadIndicator
          id="large-indicator"
          height={40}
          width={40}
        />
      </>
    );
  } else if (session) {
    return (
      <SessionContext.Provider value={session}>
        <PageManager />
      </SessionContext.Provider>
    );
  } else {
    return <ErrorNoSession />;
  }
}
