import React from 'react';

import Error from '../Error/Error';

export default function ErrorNoSession() {
  return (
    <Error
      heading="Invalid Import Session."
      text="You need to start your import session from CMS portal"
    />
  );
}
