import React from 'react';

import logo from '../../../images/MicroGuideLogo.svg';
import './Footer.css';

export default function Footer() {
  return (
    <footer className="ib-footer">
      <div className="container">
        <div className="row">
          <div className="col-auto">
            <a href="/">
              <img
                src={logo}
                alt="MicroGuide logo"
              />
            </a>
            <div className="ib-footer__sub-text">Import MS Word files</div>
          </div>
          <div className="col-auto offset-1">
            <h5 className="mb-3">Get in touch</h5>
            <ul className="ib-list-menu">
              <li>
                <a href="#">Help centre</a>
              </li>
              <li>
                <a href="#">Support</a>
              </li>
              <li>
                <a href="#">Terms & conditions</a>
              </li>
              <li>
                <a href="#">Privacy policy</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
