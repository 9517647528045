import { useCallback, useContext } from 'react';

import { putUpdateWizardStep } from '../api/guideImportSession';
import { WizardSteps } from '../api/guideImportSession/iSession';
import { useAppDispatch } from '../app/hooks';
import { updateWizardStep } from '../app/reducers/session';
import { SessionContext } from '../contexts/sessionContext';

export function useAdvanceWizardStep() {
  const dispatch = useAppDispatch();
  const session = useContext(SessionContext);

  return useCallback(
    async (nextStep: WizardSteps) => {
      void dispatch(updateWizardStep(nextStep));

      // post the wizardStep's change back to the server
      return putUpdateWizardStep(nextStep, session.sessionId, session.moShortCode);
    },
    [dispatch, session.moShortCode, session.sessionId]
  );
}

export function useSetWizardStep(){
  const dispatch = useAppDispatch();

  return useCallback(
    (nextStep: WizardSteps, sessionId:string, moShortCode:string) => {
      // post the wizardStep's change back to the server
      void putUpdateWizardStep(nextStep, sessionId, moShortCode);
    },
    [dispatch]
  );
}
