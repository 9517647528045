import React from 'react';
import './PageTemplate.css';

type IProps = {
  children: React.ReactNode;
};

export default function SubTemplate(props: IProps) {
  return (
   
     <div className="ib-container container">{props.children}</div>
   
  );
}
