import axios from 'axios';

import buildUrl from '../buildUrl';
import { CONVERSION_CONFIRM } from '../constants';
import IAxiosConfirmContentResponse from './responseTypes/IAxiosConfirmContentResponse';

export default async function putConfirmContentIntoCms(sessionId: string) {
  const url = buildUrl(CONVERSION_CONFIRM, sessionId);

  const { data: responseData }: IAxiosConfirmContentResponse = await axios.put(url);

  return responseData;
}
