import axios from 'axios';

import buildUrl from '../buildUrl';
import { STYLE_MAPPINGS_PATH } from '../constants';
import IAxiosStyleMappingResponse from './responseTypes/iAxiosStyleMappingResponse';

export default async function putMapStyle(key: number, styleName: string, styleMappings: object, sessionId: string) {
  const url = buildUrl(STYLE_MAPPINGS_PATH, sessionId);

  const data = {
    key: key, //populate this key
    styleName: styleName, //populate this text
    styleMappings: Object.entries(styleMappings)
      .filter(([, targetStyleId]) => !!targetStyleId) // filter out nullish targetStyleIds
      .map(([key, targetStyleId]) => ({
        key,
        targetStyleId,
      })),
  };

  const { data: responseData }: IAxiosStyleMappingResponse = await axios.put(url, data);

  return responseData;
}
