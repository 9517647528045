// DX components
import { Validator } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import { RequiredRule } from 'devextreme-react/data-grid';
import TextBox from 'devextreme-react/text-box';
import React, { useEffect, useState } from 'react';

import { WizardSteps } from '../../../api/guideImportSession/iSession';
// IB components
import { useAppDispatch } from '../../../app/hooks';
import { saveStyleMappings } from '../../../app/reducers/styleMappings';
import { useAdvanceWizardStep } from '../../../hooks/useAdvanceWizardStep';
import useStyleMappings from '../../../hooks/useStyleMappings';
import useTargetStyles from '../../../hooks/useTargetStyles';
import infoIcon from '../../../icons/icon-info-circle.svg';
// Icons
import settingsIcon from '../../../icons/icon-settings-circle.svg';
import Mapping from './mapping';

type IMapping = {
  [key: number]: number;
};

export default function Step2() {
  const dispatch = useAppDispatch();
  const advanceWizardStep = useAdvanceWizardStep();

  const styleMappings = useStyleMappings();
  const targetStyles = useTargetStyles();

  const [styleName, setStyleName] = useState('');
  const [mapping, setMapping] = useState<IMapping>({});

  useEffect(() => {
    setStyleName(styleMappings.styleName);
  }, [styleMappings.styleName]);

  useEffect(() => {
    setMapping(
      styleMappings.styleMappings.reduce((acc: IMapping, styleMapping) => {
        acc[styleMapping.key] = styleMapping.targetStyleId;
        return acc;
      }, {})
    );
  }, [styleMappings.styleMappings]);

  function updateMapping(targetStyle: number, style: number) {
    if (isFinite(targetStyle)) {
      setMapping({
        ...mapping,
        [style]: targetStyle,
      });
    }
  }

  async function handleFormSubmit() {
    try {
      await dispatch(
        saveStyleMappings({
          key: styleMappings.key,
          styleName: styleName,
          mapping: mapping,
        })
      ).unwrap();
      // handle result here
      await advanceWizardStep(WizardSteps.Navigation);
    } catch (rejectedValueOrSerializedError) {
      // handle error here
    }
  }

  function handlePreviousClick() {
    // eslint-disable-next-line
    advanceWizardStep(WizardSteps.UploadFiles);
  }
  return (
    <>
      <h1>Import MS Word Files</h1>
      <div className="ib-info-block align-items-center">
        <div className="ib-info-block__icon">
          <img
            src={settingsIcon}
            alt="upload icon"
            aria-hidden="true"
          />
        </div>
        <div className="ib-info-block__text">
          <h2 className="ib-info-block__heading my-0">Step 2. Map styles</h2>
        </div>
      </div>
      <p>Map the styles found in your selected Word files to those used in MicroGuide. You can either:</p>

      <ul>
        <li>
          choose from your <b>saved style settings</b>, if you&apos;ve imported Word files before, or
        </li>
        <li>
          enter a <b>style settings name</b> and manually map the Word styles to the MicroGuide styles.
        </li>
      </ul>

      <p>This mapping will be saved on import, for future use.</p>
      {/* <Collapsible open header="Saved settings">
            <p>
              The are no saved settings.
            </p>
            <ul className="ib-upload-file-list">
              <li>
                <div className="ib-upload-file-list__file-info">
                  <div>File_name_1.doc</div>
                  <div>File_name_1.doc</div>
                </div>
                <div>
                  <Button type="default" text="Apply" className='me-2' />
                  <Button type="danger" width={43} height={43} icon="trash" />
                </div>
              </li>
              <li>
                <div className="ib-upload-file-list__file-info">
                  File_name_2.doc
                </div>
                <div>
                  <Button type="default" text="Apply" className='me-2' />
                  <Button type="danger" width={43} height={43} icon="trash" />
                </div>
              </li>
              <li>
                <div className="ib-upload-file-list__file-info">
                  File_name_2.doc
                </div>
                <div>
                  <Button type="default" text="Apply" className='me-2' />
                  <Button type="danger" icon="trash" />
                </div>
              </li>
              <li>
                <div className="ib-upload-file-list__file-info">
                  File_name_2.doc
                </div>
                <div>
                  <Button type="default" text="Apply" className='me-2' />
                  <Button type="danger" icon="trash" />
                </div>
              </li>
            </ul>
          </Collapsible> */}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          void handleFormSubmit();
        }}
      >
        <div className="dx-fieldset">
          <div className="dx-field">
            <div className="dx-field-label with-comment">
              <div>
                Style settings name <span className="ib-text-danger">*</span>
              </div>
              <span className="dx-field-smallLabel">Settings will be saved after import</span>
            </div>
            <div className="dx-field-value">
              <TextBox
                value={styleName}
                placeholder="Enter a name for your style settings"
                onValueChange={(value) => setStyleName(value as string)}
              >
                <Validator>
                  <RequiredRule message={'Please enter a setting name.'} />
                </Validator>
              </TextBox>
            </div>
          </div>
        </div>

        {styleMappings.styleMappings.length > 0 && (
          <div className="dx-fieldset">
            <div className="dx-fieldset-header">
              <div className="dx-field-label">Word style</div>
              <div className="dx-field-label">MicroGuide style</div>
            </div>
            <div className="content">
              {styleMappings.styleMappings.map((styleMapping, i) => (
                <Mapping
                  key={i}
                  styleMapping={styleMapping}
                  targetStyles={targetStyles}
                  value={mapping[styleMapping.key]}
                  onChange={(selectedItemKey: number, styleMappingKey: number) =>
                    updateMapping(selectedItemKey, styleMappingKey)
                  }
                  shouldValidate = {styleMapping.sourceStyleName == "DefaultStyle"} // only validate the default style
                />
              ))}
            </div>
          </div>
        )}

        <div className="ib-info-block">
          <div className="ib-info-block__icon">
            <img
              src={infoIcon}
              alt="upload icon"
              aria-hidden="true"
            />
          </div>
          <div className="ib-info-block__text">
            <p className="ib-info-block__heading my-0">Remaining MS Word attributes will be retained</p>
            <div className="ib-info-block__description">
              If your Word files contain attributes such as bold or italic font, these will also appear in MicroGuide.
              Attributes that affect MicroGuide&apos;s look and feel, such as font sizes or font faces, will be mapped
              to MicroGuide&apos;s default &apos;paragraph&apos; style.
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div>
            <Button
              type="default"
              stylingMode="outlined"
              className="float-end"
              text="Previous"
              icon="back"
              onClick={handlePreviousClick}
            />
          </div>
          <div>
            <Button
              type="default"
              className="float-end"
              text="Continue"
              icon="back"
              rtlEnabled
              useSubmitBehavior
            />
          </div>
        </div>
      </form>
    </>
  );
}
