import { Button } from 'devextreme-react/button';
// DX components
import React, { useEffect, useState } from 'react';

import { WizardSteps } from '../../../api/guideImportSession/iSession';
// IB components
import { useAppDispatch } from '../../../app/hooks';
import { saveNavigationStyles } from '../../../app/reducers/navigationStyles';
import { useAdvanceWizardStep } from '../../../hooks/useAdvanceWizardStep';
import { useNavigationStyleOptions } from '../../../hooks/useNavigationStyles';
import documentIcon from '../../../icons/icon-document-circle.svg';
import Level from './level';

// type IStyleMappingSourceStyleNames = {
//   [key: string]: boolean;
// };

// type IStyleMapping = {
//   Name: string;
//   level: number;
// };

export default function Step3() {
  const advanceWizardStep = useAdvanceWizardStep();
  const dispatch = useAppDispatch();

  const navigationStyleOptions = useNavigationStyleOptions();
  //const styleMappings = useStyleMappings();
  const [styles, setStyles] = useState<string[]>(['']);

  //get styles already populated in this session from the server and reload state
  useEffect(() => {
    if (navigationStyleOptions.length > 0) {
      //const dupNavigationStyles = [...navigationStyleOptions];
      //dupNavigationStyles.sort((a, b) => a - b);
      //setStyles(dupNavigationStyles);
      //console.log('Styles length : ' + JSON.stringify(dupNavigationStyles));
    }
  }, [navigationStyleOptions]);

  function handlePreviousClick() {
    // eslint-disable-next-line
    advanceWizardStep(WizardSteps.Styles);
  }

  function updateLevel(style: string, level: number) {
    const dupStyles = [...styles];
    dupStyles[level] = style;
    setStyles(dupStyles);
  }

  function handleRemoveLevelClick(level: number) {
    const dupStyles = [...styles];
    dupStyles.splice(level, 1);
    setStyles(dupStyles);
  }

  function handleAddLevelClick() {
    const dupStyles = [...styles];
    dupStyles.push('');
    setStyles(dupStyles);
  }

  async function onFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    await dispatch(saveNavigationStyles(styles));
    await advanceWizardStep(WizardSteps.Preview);
  }

  return (
    <>
      <h1>Import MS Word Files</h1>
      <div className="ib-info-block align-items-center">
        <div className="ib-info-block__icon">
          <img
            src={documentIcon}
            alt="upload icon"
            aria-hidden="true"
          />
        </div>
        <div className="ib-info-block__text">
          <h2 className="ib-info-block__heading my-0">Step 3. Set navigation</h2>
        </div>
      </div>
      <p>
        To set up the navigation for your guide, identify which Word styles represent the page title, top-level pages,
        and lower-level pages. Add the levels you need. You can preview the navigation in the next step.
      </p>
      <form onSubmit={(e) => void onFormSubmit(e)}>
        <div className="dx-fieldset">
          <div className="content">
            {styles
              //.filter((style) => !style || styleMappingSourceStyleNamesDictionary[style])
              .map((style, i) => (
                <Level
                  key={i}
                  index={i}
                  style={style}
                  styleOptions={navigationStyleOptions}
                  onSelectionChanged={(sourceStyleName) => updateLevel(sourceStyleName, i)}
                  onRemove={() => handleRemoveLevelClick(i)}
                />
              ))}
            <div className="dx-field">
              <div className="dx-field-label"></div>
              <div className="dx-field-value">
                <Button
                  type="default"
                  className="float-start flex-grow-0"
                  text="Add level"
                  icon="plus"
                  onClick={handleAddLevelClick}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div>
            <Button
              type="default"
              stylingMode="outlined"
              className="float-end"
              text="Previous"
              icon="back"
              onClick={handlePreviousClick}
            />
          </div>
          <div>
            <Button
              type="default"
              className="float-end"
              text="Continue"
              icon="back"
              rtlEnabled={true}
              useSubmitBehavior={true}
              //onClick={(e) => void onFormSubmit(e)}
            />
          </div>
        </div>
      </form>
    </>
  );
}
