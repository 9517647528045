import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import SelectBox from 'devextreme-react/select-box';
import { Switch } from 'devextreme-react/switch';
import ArrayStore from 'devextreme/data/array_store';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getSessionDetail, iSessionDetail } from '../../../api/admin';
import delSession from '../../../api/admin/delSession';
import putActive from '../../../api/admin/putActive';
import { WizardSteps } from '../../../api/guideImportSession/iSession';
import { useSetWizardStep } from '../../../hooks/useAdvanceWizardStep';
import DocumentList from './documentList';
import NavigationList from './navigationList';
import StylesList from './stylesList';
import { RequiredRule, Validator } from 'devextreme-react/validator';

const  AdminWizardSteps = [
  {id: 'SessionCreated', value: 'Step0SessionCreated'},
  {id: 'DocumentUploaded', value: 'Step1DocumentUploaded'},
  {id: 'StylesMapped', value: 'Step2StylesMapped'},
  {id: 'StylesMapped', value: 'Step3StylesMapped'},
  {id: 'Finalized', value: 'Step4Finalized'},
  {id: 'Processing', value: 'Step5Processing'},
  {id: 'Processed', value: 'Step6Processed'},
  {id: 'CallBackDone', value: 'Step7CallBackDone'},
]
export default function AdminDetail() {
  const navigate = useNavigate();
  const handleOnClick = useCallback(() => navigate('/admin', { replace: true }), [navigate]);
  const setWizardStep = useSetWizardStep();
  const [popupVisible, setPopupVisible] = useState(false);
  const [data, setData] = useState<iSessionDetail>();
  const [isActive, setActive] = useState<boolean>();
  const [wizardStep, setWizardStepState] = useState<WizardSteps>();
  const [isDeleted, setIsDeleted] = useState<boolean>();
  const { id } = useParams();
  
  const dataSource = new ArrayStore({
    key : 'id',
    data: AdminWizardSteps 
  });



  useState(() => {
      getSessionDetail(id || '')
      .then((r) => {
        // save data from fetch request to state
        setData(r.data);
        setActive(r.data.session.isActive)
        setIsDeleted(r.data.session.isDeleted)
        const typedWizardStep = r.data.session.wizardStep as WizardSteps;
        setWizardStepState(typedWizardStep)
      })
      .catch(() => console.log('error'));
  });

  const onWizardStepChanged = (e: string): void => {
    const typedWizardStep = e as WizardSteps;
    if (data?.session) {
      setWizardStepState(typedWizardStep)
      setWizardStep(typedWizardStep, data?.session.sessionId, data?.session.moShortCode);
    }
  };

  const onActiveSwitchChange = async (e: any) => {
    console.log(e);
    if (data?.session.sessionId) {
      const boolValue = e as boolean;
      setActive(boolValue)
      const response = await putActive(data?.session.sessionId, boolValue); 
      if (response.isSuccess) {
        console.log('sesion status updated');
      } else {
        console.log('session status update failed');
      }
    }
  };
  const onCancelDeleteClicked = () => {
    setPopupVisible(false);
  };
  const onDeleteClicked = () => {
    setPopupVisible(true);
  };
  const deleteSession = async () => {
    setPopupVisible(false);
    if (data?.session.sessionId) {
      const response = await delSession(data?.session.sessionId);
      if (response.isSuccess) {
        console.log('sesion deleted');
        setIsDeleted(true)
      } else {
        console.log('delete session failed');
      }
    }
  };

  const cancelButtonOptions = {
    icon: 'clear',
    text: 'Cancel',
    onClick: onCancelDeleteClicked,
  };
  const deleteButtonOptions = {
    icon: 'trash',
    text: 'Delete',
    onClick: deleteSession,
  };

  return (
    <>
      <div className="container">
        <h1>Session Details</h1>
        <div className="ib-info-block__text">
          <h2 className="ib-info-block__heading my-0">
            {data?.session.guideName} - ({data?.session.sessionId})
          </h2>
        </div>
        <form>
          <div className="dx-fieldset col-12">
            <div className="dx-field col-6">
              <div className="dx-field-label col-2">Mo Name</div>
              <div className="dx-field-value-static col-2">{data?.session.moName}</div>
            </div>
            <div className="dx-field col-6">
              <div className="dx-field-label col-2">Mo Shortcode</div>
              <div className="dx-field-value-static col-2">{data?.session.moShortCode}</div>
            </div>
            <div className="dx-field col-6">
              <div className="dx-field-label col-2">Guide Shortcode</div>
              <div className="dx-field-value-static col-2">{data?.session.guideShortCode}</div>
            </div>
            <div className="dx-field col-6">
              <div className="dx-field-label col-2">Created Time</div>
              <div className="dx-field-value col-2">{moment(data?.session.createdTime).format('llll')}</div>
            </div>
            <div className="dx-field col-6">
              <div className="dx-field-label col-2">Deleted</div>
              <div className="dx-field-value col-4">
                <div className="col">{data?.session.isDeleted.toString()}</div>
                <div className="col">
                  <Button
                    type="danger"
                    onClick={onDeleteClicked}
                    disabled={isDeleted}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
           
            <div className="dx-field col-6">
              <div className="dx-field-label col-2">Active</div>
              <div className="dx-field-value col-1">
                <Switch
                  switchedOffText="Inactive"
                  switchedOnText="Active"
                  value={isActive}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onValueChange={onActiveSwitchChange}
                  disabled={isDeleted}
                />
              </div>
            </div>
            <div className="dx-field col-6">
              <div className="dx-field-label col-2">Wizard Step</div>
              <div className="dx-field-value col-2">
                <SelectBox
                  placeholder="Style"
                  dataSource={dataSource}
                  value={wizardStep}
                  searchEnabled
                  showClearButton
                  validationMessageMode={'auto'}
                  valueExpr="id"
                  displayExpr="value"
                  onValueChange={onWizardStepChanged}
                  disabled={!isActive || isDeleted }
                >
                  <Validator>
            <RequiredRule message="Selection is required" />
          </Validator>
                </SelectBox>
              </div>
            </div>
          </div>
        </form>

        <div className="dx-field">
          <DocumentList
            documents={data?.documents || []}
            sessionId={data?.session.sessionId || ''}
            isActiveDelete = {isActive && !isDeleted  || false }
          ></DocumentList>
        </div>
        <div className="dx-field">
          <StylesList styles={data?.styleMappings || []}></StylesList>
        </div>
        <div className="dx-field">
          <NavigationList navigations={data?.navigationNodes || []}></NavigationList>
        </div>

        <div className="d-flex justify-content-between">
          <div>
            <Button
              type="default"
              stylingMode="outlined"
              className="float-end"
              text="Back to Sessions"
              icon="back"
              onClick={handleOnClick}
            />
          </div>
          {/* <div>
            <Button
              type="default"
              className="float-end"
              text="Save"
              icon="back"
              rtlEnabled={true}
            />
          </div> */}
        </div>
      </div>
      <Popup
        visible={popupVisible}
        width={320}
        height={280}
        showTitle={true}
        showCloseButton={false}
        hideOnOutsideClick={false}
        title="Delete Session"
      >
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="before"
          options={deleteButtonOptions}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={cancelButtonOptions}
        />
        <p>Are you sure you want to delete this session and all of it&apos;s data?</p>
      </Popup>
    </>
  );
}
