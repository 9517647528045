import { SelectBox } from 'devextreme-react';
import React from 'react';

import { IStyleMapping } from '../../../api/styleMapping';
import ITargetStyle from '../../../api/targetStyle/iTargetStyle';
import { RequiredRule, Validator } from 'devextreme-react/validator';
type IOnChange = {
  (selectedItemKey: number, styleMappingKey: number): void;
};

type IProps = {
  styleMapping: IStyleMapping;
  targetStyles: ITargetStyle[];
  value: number;
  onChange: IOnChange;
  shouldValidate: boolean
};

export default function Mapping({ styleMapping, targetStyles, value, onChange, shouldValidate }: IProps) {
  return (
    <div className="dx-field">
      <label className="dx-field-label">{styleMapping.sourceStyleName}</label>
      <div className="dx-field-value">
        <SelectBox
          dataSource={targetStyles}
          value={value}
          valueExpr="key"
          displayExpr="name"
          searchEnabled
          showClearButton
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
          onSelectionChanged={(e) => onChange(e.selectedItem != null ? e.selectedItem.key : null, styleMapping.key)}
        >
          { shouldValidate && <Validator>
            <RequiredRule message="Selection of default style is required" />
          </Validator>
          }
        </SelectBox>
      </div>
    </div>
  );
}
