import { Button } from 'devextreme-react/button';
import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';

import { iDocumentDetails } from '../../../api/admin';
import { deleteDocuments } from '../../../api/sourceDocument';

export default function DocumentList(props: { documents: iDocumentDetails[]; sessionId: string; isActiveDelete: boolean }) {
  const deleteOperation = (documentKey: number, sessionId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      const response = await deleteDocuments(documentKey, sessionId);
      if (response.isSuccess) {
        window.location.reload()
      }
    })();
  };
  const columns: TableColumn<iDocumentDetails>[] = [
    {
      name: 'Name',
      selector: (row) => row.displayName,
      allowOverflow: false,
      grow: 2,
      style: {
        backgroundColor: 'rgba(187, 204, 221, 1)',
      },
    },
    {
      name: 'Fullname',
      selector: (row) => row.fullName,
      sortable: true,
      allowOverflow: false,
      grow: 2,
      wrap: true,
    },
    {
      name: 'Errors',
      selector: (row) => row.errors?.length,
      sortable: true,
      allowOverflow: false,
      grow: 1,
      wrap: true,
    },
    {
      name: 'Warnings',
      selector: (row) => row.warnings?.length,
      grow: 1,
      allowOverflow: false,
    },
    {
      name: 'Url',
      selector: (row) => row.downloadUrl,
      allowOverflow: false,
      wrap: true,
      grow: 3,
      // cell: (row) => <Link to={row.downloadUrl}>Details</Link>,
    },
    {
      name: 'Action',
      selector: (row) => row.key,
      allowOverflow: false,
      cell: (row) => (
        <Button
          type="danger"
          onClick={() => deleteOperation(row.key, props.sessionId)}
          disabled={!props.isActiveDelete}
        >
          Delete
        </Button>
      ),
    },
  ];
  return (
    <>
      <DataTable
        title="Documents"
        columns={columns}
        data={props.documents}
        pagination
        persistTableHead
      />
    </>
  );
}
