import axios from 'axios';

import buildUrl from '../buildUrl';
import { NAVIGATION_LEVELS_PATH, OPTIONS } from '../constants';
import { IAxiosNavigationStyleResponse, IAxiosNavigationStyleOptionsResponse } from './responseTypes/iAxiosNavigationStyleResponse';

export async function getNavigationStyleOptions(sessionId: string) {
  const url = buildUrl(NAVIGATION_LEVELS_PATH, sessionId, OPTIONS );

  const { data: responseData }: IAxiosNavigationStyleOptionsResponse = await axios.get(url, {});

  return responseData;
}

export async function getNavigationStyles(sessionId: string) {
  const url = buildUrl(NAVIGATION_LEVELS_PATH, sessionId );

  const { data: responseData }: IAxiosNavigationStyleResponse = await axios.get(url, {});

  return responseData;
}