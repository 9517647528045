import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';

import { iNavgigationDetails } from '../../../api/admin';

export default function NavigationList(props: { navigations: iNavgigationDetails[] }) {
  const columns: TableColumn<iNavgigationDetails>[] = [
    {
      name: 'Id',
      selector: (row) => row.guideNavigation.id,
      allowOverflow: false,
      grow: 1,
      style: {
        backgroundColor: 'rgba(187, 204, 221, 1)',
      },
    },
    {
      name: 'Name',
      selector: (row) => row.guideNavigation.title,
      allowOverflow: false,
      grow: 2,
      style: {
        backgroundColor: 'rgba(187, 204, 221, 1)',
      },
    },
    {
      name: 'Parent',
      selector: (row) => row.guideNavigation.parentId || '',
      sortable: true,
      allowOverflow: false,
      grow: 1,
      wrap: true,
    },
    {
      name: 'Order',
      selector: (row) => row.guideNavigation.sortOrder,
      sortable: true,
      allowOverflow: false,
      grow: 1,
      wrap: true,
    },
    {
      name: 'Leaf',
      selector: (row) => row.guideNavigation.isLeaf.toString(),
      sortable: true,
      allowOverflow: false,
      grow: 1,
      wrap: true,
    },
    {
      name: 'Html',
      selector: (row) => row.page?.template,
      sortable: false,
      allowOverflow: false,
      grow: 3,
      wrap: true,
    },
  ];
  return (
    <>
      <DataTable
        title="Navigation Nodes"
        columns={columns}
        data={props.navigations}
        pagination
        persistTableHead
      />
    </>
  );
}
