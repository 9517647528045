import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { loadNavigationStyleOptions, loadNavigationStyles, selectNavigationStyleOptions, selectNavigationStyles } from '../app/reducers/navigationStyles';

export function useNavigationStyles() {
  const dispatch = useAppDispatch();

  const navigationStyles = useAppSelector(selectNavigationStyles);

  useEffect(() => {
    if (!navigationStyles.length) {
      void dispatch(loadNavigationStyles());
    }
  }, [dispatch, navigationStyles.length]);

  return navigationStyles;
}

export function useNavigationStyleOptions() {
  const dispatch = useAppDispatch();

  const navigationStyles = useAppSelector(selectNavigationStyleOptions);

  useEffect(() => {
    if (!navigationStyles.length) {
      void dispatch(loadNavigationStyleOptions());
    }
  }, [dispatch, navigationStyles.length]);

  return navigationStyles;
}
