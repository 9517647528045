import moment from 'moment';
import React, { useMemo, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';

import { getAllSessions, iAllSessions } from '../../../api/admin';
import IbPopup from '../../organisms/IbPopup/IbPopup';

export default function AdminMain() {
  const columns: TableColumn<iAllSessions>[] = [
    {
      name: 'Session Id',
      selector: (row) => row.sessionId,
      allowOverflow: false,
      grow: 4,
      style: {
        backgroundColor: 'rgba(187, 204, 221, 1)',
      },
    },
    {
      name: 'MOShortCode',
      selector: (row) => row.moShortCode,
      sortable: true,
      allowOverflow: false,
      grow: 2,
      wrap: true,
    },
    {
      name: 'Guide Shortcode',
      selector: (row) => row.guideShortCode,
      sortable: true,
      allowOverflow: false,
      grow: 2,
      wrap: true,
    },
    {
      name: 'WizardSteps',
      selector: (row) => row.wizardStep,
      grow: 2,
      allowOverflow: false,
    },
    {
      name: 'Active',
      selector: (row) => row.isActive.toString(),
      allowOverflow: false,
      conditionalCellStyles: [
        {
          when: (row) => row.isActive == false,
          style: {
            backgroundColor: 'yellow',
          },
        },
      ],
    },
    {
      name: 'Deleted',
      selector: (row) => row.isDeleted.toString(),
      allowOverflow: false,
      conditionalCellStyles: [
        {
          when: (row) => row.isDeleted == true,
          style: {
            backgroundColor: 'red',
            color: 'white',
          },
        },
      ],
    },
    {
      name: 'Documents',
      selector: (row) => row.documentCount,
      allowOverflow: false,
    },
    {
      name: 'Styles',
      selector: (row) => row.styleCount,
      allowOverflow: false,
    },
    {
      name: 'Nav Nodes',
      selector: (row) => row.navigationNodeCount,
      allowOverflow: false,
    },
    {
      name: 'Content Pages',
      selector: (row) => row.contentPageCount,
      allowOverflow: false,
    },
    {
      name: 'Errors',
      selector: (row) => row.processingErrorCount,
      allowOverflow: false,
    },
    {
      name: 'Created',
      selector: (row) => moment(row.createdTime).format('YY/MM/DD, HH:mm:s'),
      sortable: true,
      allowOverflow: false,
      grow: 2,
      wrap: true,
    },
    {
      name: 'Action',
      cell: (row) => <Link to={'./Detail/' + row.sessionId}>Details</Link>,
      selector: (row) => row.sessionId,
      allowOverflow: false,
    },
  ];

  const [data, setData] = useState<iAllSessions[]>([]);
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = data.filter(
    (item) =>
      (item.sessionId && item.sessionId.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.moShortCode && item.moShortCode.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.guideShortCode && item.guideShortCode.toLowerCase().includes(filterText.toLowerCase()))
  );

  useState(() => {
    getAllSessions()
      .then((r) => {
        // save data from fetch request to state
        setData(r.data);
      })
      .catch(() => console.log('error'));
  });

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      console.log('Handle clear');
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <>
        <input
          id="search"
          placeholder="Search here..."
          aria-label="Search Input"
          value={filterText}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)}
        />
        <button onClick={handleClear}>x</button>
      </>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <div className="container">
        <h1>Sessions</h1>
      </div>
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        dense
      />
      <IbPopup
        visible={data.length == 0}
        showTitle={false}
        descriptionText="Loading session data..."
        titleText="Please wait"
      />
    </>
  );
}
