import { configureStore } from '@reduxjs/toolkit';

import navigationStylesReducer from './reducers/navigationStyles';
import sessionReducer from './reducers/session';
import styleMappingsReducer from './reducers/styleMappings';
import targetStylesReducer from './reducers/targetStyles';

export const store = configureStore({
  reducer: {
    navigationStyles: navigationStylesReducer,
    session: sessionReducer,
    styleMappings: styleMappingsReducer,
    targetStyles: targetStylesReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
