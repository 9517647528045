export default async function poll (fn: () => any, fnCondition: (result: any) => any, ms: number) {
    let result = await fn();
    while (!fnCondition(result)) {
      await wait(ms);
      result = await fn();
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return result;
  };

  const wait = function (ms = 1000) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };