import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

//API Endpoints
export const SESSION_PATH = 'GuideImportSession';
export const GET_SESSION_BY_ID = `${SESSION_PATH}/byId`;
export const PUT_UPDATE_WIZARD_STEP = `${SESSION_PATH}/wizardStep`;

export const UPLOAD_DOCUMENTS_PATH = 'SourceDocument';
export const TARGET_STYLES_PATH = 'TargetStyle';
export const STYLE_MAPPINGS_PATH = 'StyleMapping';
export const NAVIGATION_LEVELS_PATH = 'NavigationStyle';
export const POST_FINALIZE = 'finalize';
export const OPTIONS = 'options';
export const CONVERSION = 'Conversion';
export const CONVERSION_RESULT = 'Conversion/result';
export const CONVERSION_CONFIRM = 'Conversion/confirmContent';
export const PAGED_SESSIONS_PATH = 'Admin/PagedSession';
export const All_SESSIONS_PATH = 'Admin/AllSessions';
export const ADMIN_SESSION_DETAIL_PATH = 'Admin/Detail';
export const Delete_Source_Document = 'SourceDocument';
export const SESSION_ACTIVE = 'GuideImportSession/Active';

