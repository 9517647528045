import axios from 'axios';

import buildUrl from '../buildUrl';
import { ADMIN_SESSION_DETAIL_PATH } from '../constants';
import IAxiosSessionDetailResponse from './responseTypes/iAxiosSessionDetailResponse';

export default async function getAllSessions(sessionId : string) {
  const url = buildUrl(ADMIN_SESSION_DETAIL_PATH, sessionId);

  const { data: responseData }: IAxiosSessionDetailResponse = await axios.get(url, {});

  return responseData;
}
