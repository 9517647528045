import { Button } from 'devextreme-react/button';
import React, { useContext, useEffect, useState } from 'react';

import { WizardSteps } from '../../../api/guideImportSession/iSession';
import { ISourceDocument, deleteDocuments, postCreateDocuments } from '../../../api/sourceDocument';
import getAllDocuments from '../../../api/sourceDocument/getAllDocuments';
import { SessionContext } from '../../../contexts/sessionContext';
import { useAdvanceWizardStep } from '../../../hooks/useAdvanceWizardStep';
// Icons
import warningIcon from '../../../icons/icon-error-circle.svg';
import uploadIcon from '../../../icons/icon-upload-circle.svg';
import IbPopup from '../../organisms/IbPopup/IbPopup';
import UploadedFile from './UploadedFile';
import File from './file';
import IUploadFile from './iUploadFile';

type IFilesMapping = {
  [key: string]: IUploadFile;
};

function createUploadFile(file: File, error = ''): IUploadFile {
  return {
    file,
    uploading: false,
    uploaded: false,
    errorMessage: error,
    uploadable() {
      return !this.uploading && !this.uploaded && !this.errorMessage;
    },
  };
}

export default function Step1() {
  const advanceWizardStep = useAdvanceWizardStep();
  const session = useContext(SessionContext);

  const refUploader = React.createRef<HTMLInputElement>();
  const [filesMapping, setFilesMapping] = useState<IFilesMapping>({});
  const [uploading, setUploading] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<string>('');
  const [uploadedFiles, setUploadedFiles] = useState<ISourceDocument[]>([]);

  const files = Object.values(filesMapping);
  const existsErorrMessage = 'Document with this name already exists';

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      const res = await getAllDocuments(session.sessionId);
      if (res.data) setUploadedFiles(res.data);
    })();
  }, []);

  const handleFilesChange = (fileList: FileList | never[]) => {
    const dupFilesMapping = { ...filesMapping };

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      const exists = uploadedFiles?.find((f) => f.fileName === file.name);
      dupFilesMapping[file.name] = createUploadFile(file, exists ? existsErorrMessage : '');
    }

    setFilesMapping(dupFilesMapping);
  };

  //remove new file from UI only
  const handleFileRemove = (fileName: string) => {
    const dupFilesMapping = { ...filesMapping };
    delete dupFilesMapping[fileName];
    setFilesMapping(dupFilesMapping);
  };

  //remove uploaded file from server and uploaded list
  const handleDeleteFile = (fileName: string) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      const result = uploadedFiles?.find((f) => f.fileName === fileName);
      if (result) {
        await deleteDocuments(result.uploadId, session.sessionId);
        const dupUploadedFiles = uploadedFiles.map((a) => ({ ...a }));
        const fileToRemove = dupUploadedFiles.map((e) => e.fileName).indexOf(fileName);
        if (fileToRemove > -1) 
        {
          dupUploadedFiles.splice(fileToRemove, 1);
        }
        
        setUploadedFiles(dupUploadedFiles);
      }
    })();
  };

  const handleContinueClick = async () => {
    if (
      Object.keys(filesMapping)
        .map((k) => filesMapping[k])
        .every((file) => file.uploaded)
    ) {
      await advanceWizardStep(WizardSteps.Styles);
      return;
    } else if (
      Object.keys(filesMapping)
        .map((k) => filesMapping[k])
        .some((file) => file.errorMessage)
    ) {
      setWarningMessage('Please remove failed documents.');
      return;
    }
    setUploading(true);

    if (uploading) {
      // we're already uploading
      // show the warning message and bug out
      setWarningMessage('Document upload in progress please wait.');
      return;
    }

    const dupFilesMapping = { ...filesMapping };
    const successPromises = files
      // ignore any files which have already errored
      .filter((file) => file.uploadable())
      .map(async (file) => {
        const response = await postCreateDocuments(file.file, session.sessionId);
        dupFilesMapping[file.file.name] = {
          ...dupFilesMapping[file.file.name],
          uploading: false,
          uploaded: response.isSuccess,
          errorMessage: response.error?.errorDetail || '',
        };
        setFilesMapping(dupFilesMapping);

        return response.isSuccess;
      });

    const successResponses = await Promise.all(successPromises);
    if (successResponses.every((success) => success)) {
      setWarningMessage('');
      // eslint-disable-next-line
      await advanceWizardStep(WizardSteps.Styles);
    }
    setUploading(false);
  };

  return (
    <>
      <h1>Import MS Word Files</h1>
      <div className="ib-info-block align-items-center">
        <div className="ib-info-block__icon">
          <img
            src={uploadIcon}
            alt="upload icon"
            aria-hidden="true"
          />
        </div>
        <div className="ib-info-block__text">
          <h2 className="ib-info-block__heading my-0">Step 1. Upload files</h2>
        </div>
      </div>
      <p>
        To upload your Word files, click on the <b>Upload</b> button below.
      </p>
      <p>If you need the files imported in a particular order:</p>

      <ul>
        <li>add the word &apos;Document&apos; and a sequential number to the start of each file name</li>
        <li>include the correct file extension - either .docx or .doc</li>
      </ul>

      <p>Example: Document 1 Cardiovascular.docx, Document 2 Respiratory.docx.</p>
      <p>You can then upload these files in any order and the system will arrange them correctly.</p>

      <div className="mt-5">
        <Button
          type="default"
          text="Upload"
          icon="add"
          onClick={() => refUploader.current?.click()}
        />
      </div>

      {uploadedFiles?.length > 0 && (
        <div className="mt-5">
          <h5>Uploaded Files ({uploadedFiles.length})</h5>

          {warningMessage && (
            <p className="ib-info-block align-items-center">
              <div className="ib-info-block__icon">
                <img
                  src={warningIcon}
                  alt="warning icon"
                  aria-hidden="true"
                />
              </div>
              <div className="ib-info-block__text">{warningMessage}</div>
            </p>
          )}

          <ul className="ib-upload-file-list">
            {uploadedFiles.map((f) => (
              <UploadedFile
                removeFile={handleDeleteFile}
                fileName={f.fileName}
                key={f.fileName}
              />
            ))}
          </ul>
        </div>
      )}

      {files.length > 0 && (
        <div className="mt-5">
          <h5>Files selected ({files.length})</h5>

          {warningMessage && (
            <p className="ib-info-block align-items-center">
              <div className="ib-info-block__icon">
                <img
                  src={warningIcon}
                  alt="warning icon"
                  aria-hidden="true"
                />
              </div>
              <div className="ib-info-block__text">{warningMessage}</div>
            </p>
          )}

          <ul className="ib-upload-file-list">
            {files.map((file) => (
              <File
                key={file.file.name}
                {...file}
                removeFile={handleFileRemove}
              />
            ))}
          </ul>
        </div>
      )}

      {files.length > 0 || uploadedFiles?.length > 0 ? (
        <div className="d-flex justify-content-between">
          <div></div>
          <div>
            <Button
              type="default"
              className="float-end"
              text="Continue"
              icon="back"
              rtlEnabled
              onClick={() => void handleContinueClick()}
            />
          </div>
        </div>
      ) : null}

      <input
        style={{ opacity: '0' }}
        ref={refUploader}
        type="file"
        // accept=".docx,.doc"
        multiple
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleFilesChange(e.currentTarget.files || [])}
      />

      <IbPopup
        visible={uploading}
        showTitle={false}
        descriptionText="Please wait while your documents are being uploaded."
        titleText="Uploading Documents"
      />
    </>
  );
}
