import React from 'react';

import Error from '../Error';

export default function ErrorNoAuth() {
  return (
    <Error
      heading="Unable to Authenticate"
      text="Please try again from CMS portal"
    />
  );
}
