import axios from 'axios';

import buildUrl from '../buildUrl';
import { CONVERSION } from '../constants';
import IAxiosConversionResponse from './responseTypes/IAxiosConversionResponse';

export default async function postStartConversionProcess(sessionId: string) {
  const url = buildUrl(CONVERSION, sessionId);

  const { data: responseData }: IAxiosConversionResponse = await axios.post(url);

  return responseData;
}
