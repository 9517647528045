import React, { useContext } from 'react';

import { WizardSteps } from '../../api/guideImportSession/iSession';
import { SessionContext } from '../../contexts/sessionContext';
import { Nav } from '../organisms';
import { Main, Step1, Step2, Step3, Step4, Step5 } from '../pages';

const pageTitles = ['Upload', 'Styles', 'Navigation', 'Preview', 'Done!'];

const pageMatrix = {
  [WizardSteps.Introduction]: { element: Main, stepIndex: 0 },
  [WizardSteps.UploadFiles]: { element: Step1, stepIndex: 1 },
  [WizardSteps.Styles]: { element: Step2, stepIndex: 2 },
  [WizardSteps.Navigation]: { element: Step3, stepIndex: 3 },
  [WizardSteps.Preview]: { element: Step4, stepIndex: 4 },
  [WizardSteps.Complete]: { element: Step4, stepIndex: 4 },
  [WizardSteps.Processing]: { element: Step5, stepIndex: 5 },
  [WizardSteps.CallBackDone]: { element: Step5, stepIndex: 5 },
};

export default function PageManager() {
  const session = useContext(SessionContext);

  // todo: wizardStep can be a const once line 31 is removed
  let wizardStep =
    session.wizardStep == WizardSteps.Introduction && localStorage.getItem('skipIntroductionPage')
      ? WizardSteps.UploadFiles
      : session.wizardStep;

  // todo: remove this eventually
  // temporary for development to be able to navigate between steps
  if (process.env.NODE_ENV === 'development' && /^#\d/.test(location.hash)) {
    const stepIndex = location.hash.replace(/^#/, '');

    wizardStep = {
      0: WizardSteps.Introduction,
      1: WizardSteps.UploadFiles,
      2: WizardSteps.Styles,
      3: WizardSteps.Navigation,
      4: WizardSteps.Preview,
      5: WizardSteps.Complete,
    }[stepIndex] as WizardSteps;
  }

  const page = pageMatrix[wizardStep] || pageMatrix[WizardSteps.Introduction];
  const PageElement = page.element;

  return (
    <>
      {page.stepIndex > 0 && (
        <Nav
          stepTitles={pageTitles}
          currentStepIndex={page.stepIndex}
        />
      )}
      <PageElement />
    </>
  );
}
