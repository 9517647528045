import axios from 'axios';

import buildUrl from '../buildUrl';
import { TARGET_STYLES_PATH } from '../constants';
import IAxiosTargetStylesResponse from './responseTypes/iAxiosTargetStylesResponse';

export default async function getTargetStyles(orgShortCode: string) {
  const url = buildUrl(TARGET_STYLES_PATH, orgShortCode);

  const { data: responseData }: IAxiosTargetStylesResponse = await axios.get(url, {});

  return responseData;
}
